#outmost_idiv_education {
  width: 100vw;
}

.education {
  min-height: 100px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  /* display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; */
}

.education {
  min-height: 100px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idiv_education img {
  /* display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; */
  max-width: calc(50px + 25vw);
  height: auto;
  margin-left: 4vw;
  margin-right: 3vw;
}

.idiv_education {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.idiv_description {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
  /* font-size: calc(5px + 3vw); */
  /* margin-right: 1vw; */
}

.idiv_description p{
  margin: 10px;
  font-size: calc(10px + 0.5vw);
}

.idiv_description h1 {
  color: #226e93;
  font-family: Lato, sans-serif;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.idiv_education h2, .idiv_education h3 {
  font-weight: normal;
  margin: 0;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* color: #226e93; */
  /* font-family: Lato, sans-serif;
  font-weight: 800;
  letter-spacing: 0.5px; */
  /* text-align: center; */
  /* font-size: calc(5px + 3vw); */
  /* margin-right: 1vw; */
}

.idiv_education h2 {
  margin-top: 5px;
  font-size: 150%;
}

@media only screen and (max-width: 767px) {
  .idiv_education {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .idiv_education h1, .idiv_education h2, .idiv_education h3  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .idiv_education h1 {
    font-size: 5.5vw;
    text-align: center;
  }

  .idiv_education h2 {
    font-size: 4.5vw;
    text-align: center;
  }

  .idiv_education p {
    margin-left: 4vw;
  }
}

.display-linebreak {
  white-space: pre-line;
}

hr{
  border: 1px solid #000000;
  opacity: 0.15;
  margin-top: 3vw;
  margin-bottom: 3vw;
  margin-left: 3vw;
  margin-right: 3vw;
}