.Home {
  background-color: #f2f2f2;
  opacity: 1;
  /* margin: 3vw; */
  background: transparent;
  /* height: 100vh; */
}

/* #sectionNames{
  font-family: Verdana, sans-serif;
  color: #949494;
  font-size: calc(4px + 3.5vw);
} */

hr{
  border: 1px solid #000000;
  opacity: 0.15;
  margin-top: 3vw;
  margin-bottom: 3vw;
  margin-left: 3vw;
  margin-right: 3vw;
}

.Profile-logo {
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}

.LinkedIn-logo {
  height: calc(15px + 5vw);
  width: auto;
  pointer-events: none;
}

.KS_Logo {
  height: auto;
  margin: 0.25em;
  margin-right: 0.5em;
  margin-top: 0;
  margin-bottom: 0;
}

.KS_Logo:hover {
  cursor: pointer;
}

.ks-loading {
  height: 25vw;
  width: 25vw;
}

.Home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: 6em; */
  /* margin-bottom: 3vw; */
}

.Home-header :is(span, p) {
  color: #226e93;
  font-family: Lato, sans-serif;
  font-weight: 300;
  text-align: left;
}

/* .Typeit div {
  min-width: 350px;
} */

@media only screen and (max-width: 600px) {

  .KS_Logo {
    margin-left: 0;
  }

}

@media only screen and (max-width: 768px) {
  /* .Home-header{
    height: 69vh;
  } */

  /* .Home-header span {
    color: #226e93;
    font-family: Lato, sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
    font-size: calc(4px + 2.5vw);
  } */

  .ks-loading {
    height: 50vw;
    width: 50vw;
  }

  .Home-body {
    margin: 15vw 3vw 3vw 0vw !important;
    width: 100%;
  }

  .GitHub p {
    width: auto !important;
  }
}

.LinkedIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.LinkedIn p {
  color: #226e93;
  font-family: Lato, sans-serif;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: calc(2em);
  margin-right: 1vw;
}

.GitHub {
  align-items: center;
  text-align: center;
  padding-top: .75em;
}

.GitHub p {
  color: #226e93;
  font-family: Lato, sans-serif;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: calc(5px + 3vw);
}

.GitHub img {
  margin-left: auto !important;
  margin-right: auto !important;
}

.Home-body {
  margin: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.drawerItem{
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.Typeit {
  width: 40vw;
  height: 30vw;
  z-index: 0;
}

.particles {
  position: absolute;
  width: 100%;
  z-index: 0;
  bottom: 0;
  top: 0;
}

html {
  scroll-behavior: smooth;
}

/* #toolBarIcon {
  width: 6em;
  height: auto;
} */