.about {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#bio{
  color: #589ebf;
  font-family: Arial, Helvetica, sans-serif;
}